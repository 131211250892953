export type PageMeta = {
  dropArea: {
    enabled: boolean;
    component: any | null;
    componentProps: Record<string, any>;
    rules: ((files: File[]) => string | null)[];
  };
};

const defaultPageMeta: PageMeta = {
  dropArea: {
    enabled: false,
    component: null,
    componentProps: {},
    rules: [],
  },
};

export function usePageMeta() {
  const meta = useState("pageMeta", () => defaultPageMeta);

  onBeforeRouteLeave(() => {
    meta.value = defaultPageMeta;
  });

  return meta;
}
